'use strict';

angular.module('cpformplastApp.items')
    .factory('Category', function() {

    class Category {
        constructor({id, name, archive, dimension, dimensions, title,adjustedWidth}) {
          this.id = id;
          this.name = name;
          this.title = title;
          this.archive = archive;
          this.dimension = dimension;
          this.dimensions = dimensions;
          this.adjustedWidth = adjustedWidth;
        }
    }

    return Category;
});
